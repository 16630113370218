
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as locale_45redirectmVIwBJvLEfMeta } from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.68.0_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_terser@5.37.0_yaml@2.7.0_/node_modules/nuxt-i18n-micro/dist/runtime/components/locale-redirect.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "localized-index",
    path: "/:locale(en|cs)",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "custom-fallback-route",
    path: "/:pathMatch(.*)*",
    meta: {"globalLocaleRoutes":{}},
    component: () => import("/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.68.0_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_terser@5.37.0_yaml@2.7.0_/node_modules/nuxt-i18n-micro/dist/runtime/components/locale-redirect.vue")
  }
]